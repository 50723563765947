<template>
  <div>
    <v-snackbar
      :key="snack.id"
      variant="flat"
      location="bottom right"
      :timeout="snack.timeout"
      :model-value="true"
      data-testid="snackbar"
      @update:model-value="onInput(snack, $event)"
      v-for="snack in snacks"
    >
      <v-row align="center">
        <v-col class="flex-grow-0 mr-4" v-if="snack.icon">
          <v-icon>{{ snack.icon }}</v-icon>
        </v-col>

        <div v-html="snack.content" />
      </v-row>

      <template #actions>
        <template :key="i" v-for="(btn, i) in snack.buttons">
          <v-btn variant="text" :icon="!!btn.icon" class="mx-1" @click="btn.handler(snack)">
            <v-icon start v-if="btn.icon">{{ btn.icon }}</v-icon>
            <template v-else>{{ btn.content }}</template>
          </v-btn>
        </template>

        <v-btn variant="text" icon="mdi-close" class="ml-2 text-on-inverse-surface" @click="closeSnack(snack)" />
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useSnackbar } from '@console/composables/useSnackbar';

export default defineComponent({
  name: 'SnackbarContainer',
  setup() {
    const { snacks, closeSnack } = useSnackbar();

    const onInput = (snack, value) => {
      if (value === false) {
        closeSnack(snack);
      }
    };
    return { onInput, closeSnack, snacks };
  },
});
</script>

<style lang="scss">
.v-snack {
  .v-snack__wrapper {
    .v-btn.v-btn--icon {
      .v-icon {
        color: var(--v-on-inverse-surface);
      }
    }
  }
}
</style>
